import React from "react"
import { ShareLinks } from "../common/ShareLinks"
import styled from "styled-components"

const ShareSectionContainer = styled.div`
  width: fit-content;
  gap: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  a {
    border-radius: 10px;
    width: 24px;
    height: 22.7px;
  }
`

const ShareText = styled.p`
  font-family: var(--ff-secondary);
  font-size: 1.125em;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.0015em;
  color: rgba(0, 0, 0, 0.5);
  height: fit-content;
  margin-top: auto;
  margin-bottom: auto;
  text-transform: uppercase;
  margin-right: 0.5rem;
`

const ShareSection = () => {
  return (
    <ShareSectionContainer>
      <ShareText>Share on:</ShareText>
      <ShareLinks />
    </ShareSectionContainer>
  )
}

export default ShareSection
