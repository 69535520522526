import React from "react"
import styled from "styled-components"
import DownloadFileForm from "../hubspot/DownloadFileForm"

const ModalContent = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: 40em) {
    gap: 1rem;
  }
`

const ModalMessage = ({ formId }) => {
  return (
    <ModalContent role="alert">
      <DownloadFileForm formId={formId} />
    </ModalContent>
  )
}

export default ModalMessage
