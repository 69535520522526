import React from "react"
import styled from "styled-components"
import "swiper/css"
import "swiper/css/pagination"
import "swiper/css/navigation"
import "../../styles/carousel/carousel.css"
import StoryCarousel from "./StoryCarousel"

const NavigationSectionContainer = styled.section`
  margin-inline: auto;
  width: 100%;
  padding-bottom: 6rem;
  @media (min-width: 40em) {
    width: min(1166px, 100% - 4rem);
  }
  @media (min-width: 64em) {
    padding-bottom: 6.5rem;
  }
`

const NavigationTitle = styled.h2`
  font-family: var(--ff-secondary);
  font-weight: 800;
  font-size: 24px;
  line-height: 39px;
  color: #d2085d;
  max-width: 500px;
  margin-inline: auto;
  margin-bottom: 2.5rem;

  text-align: center;
  @media (max-width: 40em) {
    margin-bottom: 1.375rem;
    text-align: left;
    max-width: calc(100% - 2.5rem);
  }
`

const NavigationSection = ({ stories, thisStory }) => {
  const otherStories = [...stories]
  otherStories.splice(thisStory, 1)

  return (
    <NavigationSectionContainer>
      <NavigationTitle>
        Want to read more about how Rebase gives back? Check out our other
        stories
      </NavigationTitle>
      <StoryCarousel stories={stories} thisStory={thisStory} />
    </NavigationSectionContainer>
  )
}

export default NavigationSection
