import React, { createRef, useEffect } from "react"
import styled from "styled-components"
import ModalMessage from "./ModalMessage"

const ModalContainer = styled.div`
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1005;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
`

const ModalWrapper = styled.div`
  display: flex;
  position: absolute;
  max-width: calc(100% - 2.5rem);
  width: 29.4125rem;
  max-height: 40rem;
  background: #fff;
  left: 50%;
  top: 40%;
  transform: translate(-50%, calc(-50% + 3rem));
  padding: 1rem;
  padding-top: 3rem;
  padding-bottom: 2rem;

  border-radius: 9.75px;

  @media (min-width: 40em) {
    width: 34rem;
    padding: 2rem;
    padding-top: 3rem;
    padding-bottom: 2rem;
  }
`

const XShape = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 20px;
  height: 20px;
  display: inline-block;
  cursor: pointer;

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 2px;
    height: 100%;
    background-color: #828282;
    cursor: pointer;
    transition: background-color 0.1s;
  }

  &:hover::before,
  &:hover::after {
    background-color: #0f0f0f;
  }

  &::before {
    transform: translateX(-50%) rotate(45deg);
  }

  &::after {
    transform: translateX(-50%) rotate(-45deg);
  }
`

const Modal = ({ isOpen, closeModal, formId }) => {
  const closeButtonRef = createRef()

  useEffect(() => {
    //Focus trap for modal according to improve accessibility.
    document.addEventListener("keydown", function (e) {
      let isTabPressed = e.key === "Tab" || e.code === 9
      if (!isTabPressed || !isOpen) {
        return
      }
      e.preventDefault()
      if (closeButtonRef?.current) {
        closeButtonRef.current.focus()
      }
    })
  }, [closeButtonRef, isOpen])
  if (!isOpen) {
    return null
  }
  return (
    <ModalContainer onClick={closeModal}>
      <ModalWrapper onClick={e => e.stopPropagation()}>
        <ModalMessage formId={formId} />
        <XShape ref={closeButtonRef} onClick={closeModal} />
      </ModalWrapper>
    </ModalContainer>
  )
}

export default Modal
