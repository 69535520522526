import * as React from "react"
import Layout from "../components/common/Layout"
import styled from "styled-components"
import { graphql } from "gatsby"
import rehypeReact from "rehype-react"
import Meta from "../components/common/Meta"
import { getSrc } from "gatsby-plugin-image"
import ShareSection from "../components/stories/ShareSection"
import BackArrow from "../assets/svgs/back-arrow.svg"
import { navigate } from "gatsby"
import NavigationSection from "../components/stories/NavigationSection"
import MainParagraph from "../components/common/intro/MainParagraph"
import MainTitle from "../components/common/intro/MainTitle"
import DownloadLink from "../components/common/downloadLink/DownloadLink"
import DonwloadIcon from "../assets/svgs/download-icon.svg"
import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader"
deckDeckGoHighlightElement()

const BackToPreviousContainer = styled.div`
  gap: 0.625rem;
  margin-bottom: 2rem;
  align-items: center;
  cursor: pointer;
  display: none;
  @media (min-width: 64em) {
    display: flex;
  }
`

const BackToPreviousText = styled.div`
  font-family: var(--ff-primary);
  font-weight: 700;
  font-size: 1em;
  line-height: 24px;
  letter-spacing: 0.0015em;
  color: var(--clr-text-blue);
`

const TitleWrapper = styled.div`
  margin-inline: unset;
`

const TertiaryTitle = styled.h3`
  font-family: var(--ff-primary);
  font-weight: 500;
  font-size: 1.125em;
  line-height: 27px;
  letter-spacing: 0.0015em;
  color: black;

  margin-bottom: 1.5rem;
  @media (min-width: 40em) {
    margin-bottom: 2rem;
    font-weight: 600;
  }
`

const Paragraph = styled.p`
  font-family: var(--ff-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 0.875em;
  line-height: 21px;
  letter-spacing: 0.0015em;

  margin-bottom: 3rem;
  @media (min-width: 40em) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 2.5rem;
  }
`

const SubHeading = styled(MainParagraph)`
  margin-bottom: 2rem;
  @media (min-width: 40em) {
    margin-bottom: 3rem;
  }
`

const StyledBlockquote = styled.blockquote`
  margin-bottom: 4rem;
  margin-top: 4rem;
  p {
    color: #d2085d;
    font-family: var(--ff-secondary);
    font-weight: 700;
    font-size: 1.5em;
    line-height: 39px;
    margin: 0;
  }
  @media (min-width: 40em) {
    margin-right: 1rem;
    margin-top: 0;
    margin-bottom: 2rem;
    float: right;
    margin-left: 2.2rem;
    width: 50%;
    max-width: ${props => props.maxwidth};
  }
  @media (min-width: 64em) {
    margin-left: 8.5rem;
  }
`

const Blockquote = ({ children }) => {
  const quoteLength = children[1].props.children[0].length
  const maxwidth = quoteLength < 120 ? `${25}ch` : `${35}ch`
  return <StyledBlockquote maxwidth={maxwidth}>{children}</StyledBlockquote>
}

const DownloadLinkText = styled.div`
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 1.5rem;

  border: 0;
  border-radius: 0px;
  font-family: var(--ff-secondary);
  font-weight: 700;
  font-size: 1rem;
  letter-spacing: 0.0046em;
  color: white;

  background-color: var(--clr-primary);
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  position: relative;
  transition: 0.2s ease-in-out;

  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;

  &:hover {
    background-color: var(--clr-tertiary-bright);
    color: var(--clr-text-blue);

    svg {
      fill: var(--clr-text-blue);
    }
    path {
      fill: var(--clr-text-blue);
    }
  }

  margin-bottom: 2.5rem;

  @media (min-width: 40em) {
    padding-top: 1rem;
    padding-bottom: 1rem;
    letter-spacing: 0.0025em;
    text-align: center;
  }

  transition: 0.2s ease-in-out;
`

const Download = ({ data, children }) => {
  console.log(data)
  const formId = data?.markdownRemark?.frontmatter?.formId

  return (
    <DownloadLink formId={formId} data={data}>
      <DownloadLinkText>
        <DonwloadIcon width={20} height={20} style={{ minWidth: "20px" }} />
        {children}
      </DownloadLinkText>
    </DownloadLink>
  )
}

const Main = styled.main`
  position: relative;
  padding-top: 6.875rem;

  @media (min-width: 40em) {
    padding-top: 5.5rem;
  }

  @media (min-width: 64em) {
    padding-top: 5.5rem;
  }
`

const Container = styled.div`
  margin-inline: auto;
  padding-bottom: 5rem;
  width: min(1000px, 100% - 2rem);
  max-width: 49.625rem;
  @media (min-width: 40em) {
    padding-bottom: 5.5rem;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  margin-inline: auto;

  .gatsby-resp-image-wrapper {
    margin-left: 0 !important;
    overflow: clip;
    margin-bottom: 2.5rem;
    @media (min-width: 40em) {
      margin-bottom: 3.625rem;
    }
  }

  a {
    text-decoration: none;
    color: var(--clr-text-blue);

    &:hover {
      text-decoration: underline;
    }
  }

  h1 {
    margin-inline: unset;
  }

  figcaption {
    display: none;
  }

  img {
    background-color: white;
  }

  ul {
    margin-left: 1rem;
    font-family: var(--ff-primary);
    font-style: normal;
    font-weight: 400;
    font-size: 0.875em;
    line-height: 21px;
    letter-spacing: 0.0015em;
    margin-bottom: 3rem;
    @media (min-width: 40em) {
      margin-bottom: 2.5rem;
      font-size: 16px;
      line-height: 24px;
    }
  }

  em {
    font-style: italic;
  }
`

export const Head = ({ data }) => (
  <Meta
    title={
      data.markdownRemark.frontmatter.metaTitle ||
      data.markdownRemark.frontmatter.summaryTitle
    }
    description={
      data.markdownRemark.frontmatter.metaDes ||
      data.markdownRemark.frontmatter.summary
    }
    image={`http://rebase.fi.s3-website-eu-west-1.amazonaws.com${getSrc(
      data.markdownRemark.frontmatter.summaryImage
    )}`}
  />
)

const StoryTemplate = ({ data }) => {
  const { htmlAst } = data.markdownRemark
  const stories = data.allMarkdownRemark.nodes

  const thisStory = stories.findIndex(story => {
    return story.frontmatter.date === data.markdownRemark.frontmatter.date
  })

  const goToPrevious = () => {
    navigate(-1)
  }

  const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: {
      h2: SubHeading,
      h3: TertiaryTitle,
      p: Paragraph,
      blockquote: Blockquote,
      h6: props => <Download data={data} {...props} />,
    },
  }).Compiler

  return (
    <Layout headerBgColor={"#7052cdcc"}>
      <Main>
        <Container>
          <BackToPreviousContainer onClick={goToPrevious}>
            <BackArrow color="E00038" />
            <BackToPreviousText>Back to previous page</BackToPreviousText>
          </BackToPreviousContainer>
          <TitleWrapper>
            <MainTitle
              title={JSON.parse(data.markdownRemark.frontmatter.mainTitle)}
            />
          </TitleWrapper>
          <Content>{renderAst(htmlAst)}</Content>
          <ShareSection />
        </Container>
        <NavigationSection stories={stories} thisStory={thisStory} />
      </Main>
    </Layout>
  )
}

export default StoryTemplate

export const query = graphql`
  query StoryPage($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        date
        title
        formId
        slug
        writer
        mainTitle
        summary
        summaryTitle
        summaryImage {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        metaTitle
        metaDes
      }
      htmlAst
      headings {
        value
      }
    }

    allMarkdownRemark(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { fileAbsolutePath: { regex: "/src/data/stories/" } }
    ) {
      nodes {
        frontmatter {
          slug
          summaryTitle
          summary
          date
          summaryImage {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
        id
      }
    }
  }
`
