import React, { useState } from "react"
import Modal from "./Modal"

const DownloadLink = ({ formId, children }) => {
  const [showModal, setShowModal] = useState(false)

  const toggleModal = () => {
    setShowModal(!showModal)
  }

  return (
    <>
      <span onClick={toggleModal}>{children}</span>
      <Modal isOpen={showModal} closeModal={toggleModal} formId={formId} />
    </>
  )
}

export default DownloadLink
