import React, { useEffect } from "react"
import { Script } from "gatsby"
import styled from "styled-components"

// Styled components to match your input styles
const FormContainer = styled.div`
  width: 100%;
`

const StyledHubspotForm = styled.div``

const DownloadFileForm = ({ formId }) => {
  useEffect(() => {
    const injectStylesIntoIframe = () => {
      const iframe = document.querySelector("#hubspotForm iframe")
      if (iframe) {
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document
        const style = iframeDocument.createElement("style")
        style.type = "text/css"
        style.appendChild(
          iframeDocument.createTextNode(`
            .hbspt-form {
              padding: 1px;
            }
            .actions {
              margin: 0 !important;
              padding: 0 !important;
            }

            .hs-richtext {
              margin-right: auto;
              color: black !important;
              font-size: 0.875rem;
              font-weight: 400;
              letter-spacing: 0.0015em;
              text-align: left;
              margin-bottom: 1rem !important;
            }
            a {
              cursor: pointer;
              color: #1008bd;
            }

            a:hover {
              text-decoration: underline;
              color: #1008bd !important;
            }

            .hs-input {
              font-weight: 400 !important;
              font-size: 0.75em !important;
              font-size: 0.875em !important;
              -webkit-letter-spacing: 0.004em !important;
              -moz-letter-spacing: 0.004em !important;
              -ms-letter-spacing: 0.004em !important;
              letter-spacing: 0.004em !important;
              height: 2.781rem; !important;
              padding: 0.47rem !important;
              background-color: #ffffff !important;
              border: 0 !important;
              box-shadow: rgb(122, 121, 121, 0.3) 0px 0px 0px 1px !important;
              color: var(--clr-text-black) !important;
            }

            .hs-submit .hs-button {
              margin-top: 1rem;
              appearance: none;
              -webkit-appearance: none;
              width: 100%;
              border: 0;
              background-color: #1008bd;
              transition: 0.2s ease-in-out;
              font-size: 0.8125em;
              color: white;
              font-family: 'Poppins' !important;
              font-weight: 500;
              text-transform: uppercase;
              cursor: pointer;
              height: 35px;
              width: 123px;
              letter-spacing: 0.0046em;
              margin: 0;
            }
            .primary:hover {
              color: #1008bd !important;
              background-color: #3bf7d0 !important;
            }

            ul {
              margin: 5px 0 0px !important; 
            }

            .hs-error-msg {
              font-size: 0.75rem !important;
              color: #A80101 !important;
            }

            .submitted-message {
              margin-right: auto;
              color: black;
              font-size: 0.875rem;
              font-weight: 400;
              letter-spacing: 0.0015em;
              text-align: left;
              margin-bottom: 1rem !important;
            }
          `)
        )
        iframeDocument.head.appendChild(style)
      }
    }

    const interval = setInterval(() => {
      const iframe = document.querySelector("#hubspotForm iframe")
      if (iframe) {
        clearInterval(interval)
        injectStylesIntoIframe()
      }
    }, 500)

    return () => clearInterval(interval)
  }, [])

  return (
    <FormContainer>
      <StyledHubspotForm id="hubspotForm"></StyledHubspotForm>
      <Script
        src="//js.hsforms.net/forms/embed/v2.js"
        type="text/javascript"
        onLoad={() => {
          if (window.hbspt) {
            window.hbspt.forms.create({
              region: "eu1",
              portalId: "47755397",
              formId: formId,
              target: "#hubspotForm",
              css: "",
            })
          }
        }}
      />
    </FormContainer>
  )
}

export default DownloadFileForm
