import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { useLocation } from "@reach/router"

export const ShareLinks = () => {
  const { pathname } = useLocation()
  return (
    <>
      <a
        href={`https://twitter.com/intent/tweet?text=https://rebase.fi${pathname}`}
        target="_blank"
        rel="noreferrer"
      >
        <StaticImage
          alt="twitter share"
          src="../../assets/images/twittergray.png"
          quality={100}
        />
      </a>
      <a
        href={`https://www.linkedin.com/sharing/share-offsite/?url=rebase.fi${pathname}`}
        target="_blank"
        rel="noreferrer"
      >
        <StaticImage
          alt="linkedin share"
          src="../../assets/images/linkedingray.png"
          quality={100}
        />
      </a>
    </>
  )
}
